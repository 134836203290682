import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Cards from '../Cards'; // Import Cards if necessary
import '../Home.css'; // Import the corresponding CSS file for the home page

function Home() {
  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }} // Starting animation state
      animate={{ opacity: 1, x: 0 }}   // Ending animation state
      exit={{ opacity: 0, x: -100 }}   // Exit animation state
      transition={{ duration: 0.5 }}   // Animation duration
    >
      {/* Hero Section */}
      <div className="hero-container">
        {/* Dynamic Flowing Waves */}
        <div className="flowing-waves">
          <svg
            id="visual"
            viewBox="0 0 1200 150" // Adjusted viewBox for wider coverage
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            className="wave-svg"
          >
            <rect x="0" y="0" width="1200" height="150" fill="#F5F5F5"></rect> {/* Matches background color */}
            <path
              d="M0 90L129 80L257 95L386 85L514 95L643 100L771 90L900 95L1029 85L1157 95L1200 100L1200 150L900 150L771 150L643 150L514 150L386 150L257 150L129 150L0 150Z"
              fill="#009473"
            ></path>
            <path
              d="M0 100L129 110L257 115L386 105L514 115L643 110L771 115L900 110L1029 105L1157 115L1200 120L1200 150L900 150L771 150L643 150L514 150L386 150L257 150L129 150L0 150Z"
              fill="#00785e"
            ></path>
            <path
              d="M0 115L129 120L257 125L386 120L514 125L643 120L771 125L900 120L1029 115L1157 125L1200 130L1200 150L900 150L771 150L643 150L514 150L386 150L257 150L129 150L0 150Z"
              fill="#005d49"
            ></path>
          </svg>
        </div>

        {/* Overlay Content */}
        <div className="overlay-content">
          <motion.h1
            className="home-title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <span className="home-title-green">Chheng</span>
            <span className="home-title-wheat">Computers</span>
          </motion.h1>
          <motion.p
            className="home-tagline"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Build Better | Save More
          </motion.p>
          <motion.div
            className="home-buttons"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <Link className="btns" to="/computers">My Builds</Link>
            <Link className="btns" to="/contactme">Start Here</Link>
          </motion.div>
        </div>
      </div>

      {/* Cards Section */}
      <div className="cards-section">
        <Cards />
      </div>
    </motion.div>
  );
}

export default Home;
