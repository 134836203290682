import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import '../../App.css';
import '../AboutMe.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function AboutMe() {
  const [currentSlide, setCurrentSlide] = useState(0); // State to track active slide
  const sliderRef = useRef(null); // Ref to control the slider

  const timelineData = [
    {
      title: 'The Start of ChhengComputers',
      date: 'Summer of 2022',
      imgSrc: '/images/start-1.jpg',
      details:
        'One day after I was going through things to sell before college and thought that it was time to say goodbye to my computer. Right before going through the door, I dropped it, shattering in millions of pieces. Having called the manufacturer, the only solution was to build it again.',
    },
    {
      title: 'My First Computer',
      date: 'Mid Summer of 2022',
      imgSrc: '/images/start-2.jpg',
      details:
        'Weeks after my computer was sold, I thought it would be much more exciting to build a new computer since I still needed it for school and a few of my games that I used to play. So then, I researched more about each individual part and began to build my first ever computer.',
    },
    {
      title: 'Mixing it up',
      date: 'Mid Summer of 2022',
      imgSrc: '/images/first-1.jpg',
      details:
        'Then at some point, I looked back at my computer and realized that I could build something much better for much cheaper. I ended up finding a guy on Facebook Marketplace and selling it to him for about $1400. I was excited to say that it was my first ever real sale.',
    },
    {
      title: 'What it is today',
      date: 'July 24',
      imgSrc: '/images/Screenshot 2024-09-11 at 7.58.17 PM.png',
      details:
        'After that moment, I began to sell about 50 more computers and started to scale the business to students at UT Dallas and buyers on Facebook marketplace. In doing so, I thought it would be in my best interest to build a website to host my services and help others in need.',
    },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (_, next) => setCurrentSlide(next), // Update active slide index
  };

  return (
    <div className="aboutme-container">
      <motion.div
        className="aboutme-header"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="aboutme-header">About Me</h1>
      </motion.div>

      <div className="aboutme-content">
        <img
          src="images/Michael_Chheng_Headshot .jpg"
          alt="Michael Chheng"
          className="my-photo"
        />
        <div className="aboutme-info">
          <h2 className="title">Michael Chheng</h2>
          <p className="content">
            Hi, I'm Michael, born and raised in Fort Worth, TX. I am currently a
            senior at UT Dallas studying Business Analytics and Artificial
            Intelligence. I started to pursue this passion to help serve those in college in need of affordable tech solutions.
          </p>
        </div>
      </div>

      <h2 className="timeline-title">Read More About My Journey</h2>
      <Slider ref={sliderRef} {...settings} className="carousel-container">
        {timelineData.map((item, index) => (
          <div key={index} className="timeline-box">
            <img src={item.imgSrc} alt={item.title} className="timeline-image" />
            <h3>{item.title}</h3>
            <p className="timeline-date">{item.date}</p>
            <p className="timeline-details">{item.details}</p>
          </div>
        ))}
      </Slider>

      {/* Pagination Controls */}
      <div className="carousel-pagination">
        {timelineData.map((_, index) => (
          <button
            key={index}
            className={`pagination-btn ${currentSlide === index ? 'active' : ''}`}
            onClick={() => {
              sliderRef.current.slickGoTo(index); // Move to the corresponding slide
              setCurrentSlide(index); // Update the active slide index
            }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}
