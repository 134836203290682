import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, addDoc, query, where, onSnapshot } from 'firebase/firestore';
import { motion } from 'framer-motion';
import '../../App.css';
import '../Reviews.css';

export default function Reviews() {
  const [currentReview, setCurrentReview] = useState({
    stars: 0,
    userComment: "",
    userName: "",
  });
  const [approvedReviews, setApprovedReviews] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    // Fetch approved reviews from Firestore
    const reviewsQuery = query(collection(db, 'reviews'), where('approved', '==', true));
    const unsubscribe = onSnapshot(reviewsQuery, (snapshot) => {
      const reviews = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setApprovedReviews(reviews);
    });
    return () => unsubscribe();
  }, []);

  const handleStarClick = (stars) => {
    setCurrentReview({ ...currentReview, stars });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'reviews'), {
        ...currentReview,
        approved: false,
        createdAt: new Date(),
      });

      setSubmitStatus("Review submitted for approval!");
    } catch (error) {
      console.error("Error adding document: ", error);
      setSubmitStatus("Failed to submit review. Please try again.");
    }

    setCurrentReview({
      stars: 0,
      userComment: "",
      userName: "",
    });
  };

  return (
    <motion.div
      className="reviews-page"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
    <h1 className="reviews-title">Reviews</h1>
      <div className="reviews-container">
        {/* Input Section Moved to Top */}
        <form className="review-form" onSubmit={handleSubmit}>
          <div className="star-rating">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={star <= currentReview.stars ? 'star filled' : 'star'}
                onClick={() => handleStarClick(star)}
              >
                &#9733;
              </span>
            ))}
          </div>
          <textarea
            placeholder="Write your comment"
            value={currentReview.userComment}
            onChange={(e) => setCurrentReview({ ...currentReview, userComment: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Name (optional)"
            value={currentReview.userName}
            onChange={(e) => setCurrentReview({ ...currentReview, userName: e.target.value })}
          />
          <button type="submit" disabled={!currentReview.stars || !currentReview.userComment}>
            Submit Review
          </button>
        </form>

        {submitStatus && <p className="submit-status">{submitStatus}</p>}

        {/* Approved Reviews Section */}
        <div className="approved-reviews">
          {approvedReviews.map((review, index) => (
            <div key={index} className="review">
              <div className="star-rating">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={star <= review.stars ? 'star filled' : 'star'}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
              <p>{review.userComment}</p>
              {review.userName && <p className="review-name">- {review.userName}</p>}
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}