import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  const cardsData = [
    {
      src: '/images/74659980855__2B6CB559-DC4A-4B1A-B079-FBD301A8A388.jpg',
      text: 'My most recent build',
      label: 'Aesthetic white build',
      path: '/computers',
    },
    {
      src: '/images/IMG_6093.jpg',
      text: '4060ti gaming machine under $1,100',
      label: 'Aesthetic black build',
      path: '/computers',
    },
    {
      src: '/images/IMG_3946 2.jpg',
      text: 'Workstation for college students ',
      label: 'Gaming on a budget',
      path: '/computers',
    },
    {
      src: '/images/IMG_6014.jpg',
      text: 'Compact and powerful pink mini-PC',
      label: 'Pink cute build',
      path: '/computers',
    },
  ];

  return (
    <div className="cards">
      <div className="cards__header">
        <h1>Check out our latest builds!</h1>
      </div>
      <div className="cards__container">
        <div className="cards__track">
          {cardsData.map((card, index) => (
            <CardItem
              key={index}
              src={card.src}
              text={card.text}
              label={card.label}
              path={card.path}
            />
          ))}
          {/* Duplicate cards for seamless looping */}
          {cardsData.map((card, index) => (
            <CardItem
              key={`duplicate-${index}`}
              src={card.src}
              text={card.text}
              label={card.label}
              path={card.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Cards;
