import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '../../App.css';
import '../Computers.css';

export default function Computers() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const computersData = [
    {
      title: 'Most Recent Computer',
      imgSrc: '/images/most_recent_computer.jpg',
      description: 'Take a look at my most recent computer',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '750W Gold EVGA',
        GPU: '3070Ti ASUS ROG Strix',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '32GB Corsair CL18',
        CASE: 'LIAN LI Vision 011',
        COOLER: 'LIAN LI Galahad II',
      },
      fpsDetails: 'Valorant: 400 FPS | GTA V: 180 FPS',
      saved: 300,
      soldFor: 1300,
    },
    {
      title: 'Intel i5 12600k Build',
      imgSrc: '/images/IMG_2744 2.jpg',
      description: 'One of my most popular builds for an insanely cheap price',
      details: {
        CPU: 'Intel i5 12600k',
        PSU: '650W Gold EVGA',
        GPU: 'MSI 3060Ti',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '16GB',
        CASE: 'NZXT H9 Flow',
        COOLER: 'LIAN LI Galahad I',
      },
      fpsDetails: 'Valorant: 350 FPS | GTA V: 160 FPS',
      saved: 200,
      soldFor: 1300,
    },
    {
      title: 'The Pinterest Post',
      imgSrc: '/images/IMG_2668_2.jpg',
      description: 'Smooth and aesthetically pleasing all white powered machine',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '750W Gold EVGA',
        GPU: '3070 Gigabyte Vision',
        STORAGE: '1TB Samsung 970 EVO PLUS',
        RAM: '16GB',
        CASE: 'NZXT X53 White',
        COOLER: 'NZXT KRAKEN AIO 280mm',
      },
      fpsDetails: 'Valorant: 320 FPS | GTA V: 140 FPS',
      saved: 300,
      soldFor: 900,
    },
    {
      title: 'Perfect College Build',
      imgSrc: '/images/IMG_3946 2.jpg',
      description: 'Cheap budget build where power meets affordability',
      details: {
        CPU: 'AMD Ryzen 5 5600x',
        PSU: '650W Gold EVGA',
        GPU: 'AMD 6650XT',
        STORAGE: '1Gb SSD',
        RAM: '16GB',
        CASE: 'Corsair 4000D',
        COOLER: 'AMD Stock Cooler',
      },
      fpsDetails: 'Valorant: 280 FPS | GTA V: 120 FPS',
      saved: 350,
      soldFor: 800,
    },
    {
      title: 'All Black with Custom AIO',
      imgSrc: '/images/IMG_6093.jpg',
      description: 'Sleek black design with a custom LCD display',
      details: {
        CPU: 'Intel i9 10900K',
        PSU: '750W Gold',
        GPU: 'RTX 4060ti',
        STORAGE: '2TB Samsung 970 EVO PLUS',
        RAM: '32GB Corsair Dominator',
        CASE: 'NZXT H9 Flow',
        COOLER: 'NZXT Kraken',
      },
      fpsDetails: 'Valorant: 420 FPS | GTA V: 200 FPS',
      saved: 400,
      soldFor: 1100,
    },
    {
      title: 'Small Pink Build',
      imgSrc: '/images/IMG_6014.jpg',
      description: 'Cute pink compact computer',
      details: {
        CPU: 'Intel i5 10400',
        PSU: '550W Bronze',
        GPU: 'GTX 1660 Ti',
        STORAGE: '1TB SSD',
        RAM: '16GB',
        CASE: 'InWin A1 Mini ITX',
        COOLER: 'Noctua NH-L9i',
      },
      fpsDetails: 'Valorant: 250 FPS | GTA V: 100 FPS',
      saved: 200,
      soldFor: 700,
    },
    {
      title: 'Classic, Affordable, Functional',
      imgSrc: '/images/IMG_3371 2.jpg',
      description: 'Affordable build with reliable performance',
      details: {
        CPU: 'Intel i3 10100F',
        PSU: '450W Bronze',
        GPU: 'GTX 1650',
        STORAGE: '500GB SSD',
        RAM: '8GB',
        CASE: 'Cooler Master MasterBox Q300L',
        COOLER: 'Stock Cooler',
      },
      fpsDetails: 'Valorant: 200 FPS | GTA V: 90 FPS',
      saved: 350,
      soldFor: 750,
    },
  ];

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <motion.div
      className="computers-container"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="computers-title">Computers</h1>
      <div className="computer-cards">
        {computersData.map((computer, index) => (
          <div key={index} className="computer-card">
            <div onClick={() => toggleExpand(index)} className="card-header">
              <div className="sold-price">{`Saved $${computer.saved}`}</div>
              <img
                src={computer.imgSrc}
                alt={computer.title}
                className="computer-image"
              />
              <h4>{computer.title}</h4>
              <p className="computer-description">{computer.description}</p>
            </div>

            {/* Expanded Content Inline */}
            {expandedIndex === index && (
              <motion.div
                className="expanded-content"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                transition={{ duration: 0.4 }}
              >
                <h3 className="sold-price-modal">{`Sold for $${computer.soldFor}`}</h3>
                {Object.entries(computer.details).map(([key, value]) => (
                  <p key={key}>
                    <span className="spec-label">{key}:</span> {value}
                  </p>
                ))}
                <p className="fps-details">
                  <span className="spec-label">FPS:</span> {computer.fpsDetails}
                </p>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  );
}
