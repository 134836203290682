import React from 'react';
import { motion } from 'framer-motion'; // Import motion for animations
import '../../App.css';
import '../Guidebook.css';

export default function Guidebook() {
  return (
    <motion.div
      className="guidebook-container"
      initial={{ opacity: 0, x: 100 }} // Starting state (off-screen)
      animate={{ opacity: 1, x: 0 }}  // Ending state (visible and in place)
      exit={{ opacity: 0, x: -100 }}  // Exit state (slide out)
      transition={{ duration: 0.5 }}  // Transition duration
    >
      <h1 className="guidebook-header">Guidebook</h1>
      <div className="iframe-container">
        {/* Check if the iframe is supported */}
        <iframe
          src="/Chheng Computers Guidebook.pdf" // Use the relative path from the public folder
          width="100%"
          height="800px"
          allow="autoplay"
          title="Guidebook"
          style={{ border: 'none' }}
        ></iframe>
        <p className="fallback-text">
          If the guidebook does not load, <a href="/Chheng Computers Guidebook.pdf" target="_blank" rel="noopener noreferrer">click here</a> to view or download it.
        </p>
      </div>
    </motion.div>
  );
}
